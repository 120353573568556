import resolveBaseUrl from "../services";

export const amountToCurrency = (amount: number) => {
  return amount.toLocaleString("en-US");
};

export const formattedDate = (dateStr: string) => {
  if (dateStr !== undefined && dateStr != null && dateStr.length >= 10) {
    let date = new Date(dateStr);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  } else {
    return dateStr;
  }
};

export const dateToDbFormat = (date: Date) => {
  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = date.getFullYear();
  
  return yyyy + '-' + mm + '-' + dd;
};

export const currentDate = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  
  return yyyy + '-' + mm + '-' + dd;
};

export const invoiceStartDate = () => {

  var today = new Date();
  var date= new Date(today.getFullYear(), today.getMonth()+1, 0);
  
  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = date.getFullYear();
  
  return yyyy + '-' + mm + '-01';
};


export const invoiceEndDate = () => {

  var today = new Date();
  var date= new Date(today.getFullYear(), today.getMonth()+1, 0);
  
  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = date.getFullYear();
  
  return yyyy + '-' + mm + '-' + dd;
};

export const setCurrentTenant = (tenant: any) => {
  localStorage.setItem("__treda__tenant__", JSON.stringify({
    id: tenant.id,
    tenantcode: tenant.tenantcode,
    name: tenant.name,
    logo: tenant.logo_path,
    logo_path: tenant.logo_path,
    category: tenant.category,
    schooltype: tenant.schooltype,
    forms: tenant.forms
  }))
}

export const currentTenant = () => {
  const current_user = localStorage.getItem("__treda__user__");
  const current_tenant = localStorage.getItem("__treda__tenant__");
  var tenant = { name: "-/-", logo: "-/-", phone: "-/-", email: "-/-", logo_path: "-/-", id: 0, category: "---", tenantcode: "---", schooltype: "primary", forms: "" };
  if (current_user) {
    const user = JSON.parse(current_user);
    if (current_tenant) {
      tenant = JSON.parse(current_tenant);
    } else {
      if (user.tenants.length > 0) {
        tenant = user.tenants[0];
      }
    }
  }
  return tenant;
};

export const allTenants = () => {
  let tenants: any = [];
  const current_user = localStorage.getItem("__treda__user__");
  if (current_user) {
    const user = JSON.parse(current_user);
    tenants = user.tenants;
  }
  return tenants;
} 

export const fullUrl = (url: string) => {
  const API_URL = "https://apiv3.treda.africa";
  if (url && !url.startsWith('http')) {
    url = API_URL + url;
  }
  return url;
};

export const generateRandomColors = (limit: number) => {
  const list: string[] = [];
  for(let i = 0; i < limit; i++) {
    var randomColor = "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);});
    list.push(randomColor);
  }
  return list;
}

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
}; 

export const isLoggedIn = () => {
  const token = localStorage.getItem("__treda__access__token__");
  let result = false;
  if (token) {
    const decodedJwt = parseJwt(token);
    if (decodedJwt.exp * 1000 > Date.now()) {
      result = true;
    }
  }

  if (!result) {
    localStorage.removeItem("__treda__access__token__");
    localStorage.removeItem("__treda__user__");
    localStorage.removeItem("__treda__tenant__");
  }

  return result;
}

export const getForms = () => {
  const tenant = currentTenant();
  if (tenant.schooltype && tenant.schooltype == "secondary") {
    return [
      {value: 'S1', text: 'S1'},
      {value: 'S2', text: 'S2'},
      {value: 'S3', text: 'S3'},
      {value: 'S4', text: 'S4'},
      {value: 'S5', text: 'S5'},
      {value: 'S6', text: 'S6'},
    ]
  } else {
    if (tenant.forms && tenant.forms.length > 0) {
      return tenant.forms.split(",").map((form: string) => {
        return {value: form, text: form}
      })
    } else {
      return [
        {value: 'Nursery', text: 'Nursery'},
        {value: 'Kinder', text: 'Kinder'},
        {value: 'Pre-primary', text: 'Pre-primary'},
        {value: 'P1', text: 'P1'},
        {value: 'P2', text: 'P2'},
        {value: 'P3', text: 'P3'},
        {value: 'P4', text: 'P4'},
        {value: 'P5', text: 'P5'},
        {value: 'P6', text: 'P6'},
        {value: 'P7', text: 'P7'},
      ]
    }
  }
}

export const getTerms = () => {
  return [
    {value: 'I', text: 'I'},
    {value: 'II', text: 'II'},
    {value: 'III', text: 'III'},
  ]
}

export const getStorableUser = (data: any) => {
  const permissions: any = {};
  let is_admin = null;
  if (data.is_admin || data.admin) {
    is_admin = true
  }
  if (data.roles)  {
    data.roles.map((role: any) => {
      if (role.permissions) {
        role.permissions.map((permission: any) => {
          if (!permissions[permission.subject_class]) {
            permissions[permission.subject_class] = []
          }
          permissions[permission.subject_class].push(permission.action)
        })
      }

      if(role.name == "superadmin") {
        is_admin = true
      }
    })
  }
  const user = {
    id: data.id,
    avatar: `${resolveBaseUrl()}/${data.path}`,
    name: data.name,
    is_admin: is_admin,
    admin: is_admin,
    email: data.email,
    roles: data.roles,
    permissions: permissions,
    tenants: data.tenants.map((tenant: any) => ({
      id: tenant.id,
      tenantcode: tenant.tenantcode,
      name: tenant.name,
      logo: tenant.logo_path,
      logo_path: tenant.logo_path,
      category: tenant.category,
      schooltype: tenant.schooltype,
      forms: tenant.forms
    }))
  }

  return user;
}

export const can = (action: string, clazz: string, object: any = null) => {
  let result = false;
  const current_user = localStorage.getItem("__treda__user__");
  if (current_user) {
    const user = JSON.parse(current_user);
    if (clazz.length == 0 ) {
      result = true
    } else if (user.admin) {
      result = true;
    } else if (user.permissions) {
      const permissions = user.permissions[clazz];
      if (permissions && (permissions.includes(action) || permissions.includes('manage'))) {
        result = true
      }
    }
  }
  return result;
}
